// HEADER DATA JSON
export const menuData = [
  {
    id: 1,
    menu: "Who we are",
    url: "/",
  },
  {
    id: 2,
    menu: "Scoo Rentals",
    url: "/ScooRentals",
  },
  {
    id: 3,
    menu: "Scoo Luxe",
    url: "/scooluxe",
  },
  {
    id: 4,
    menu: "Scoo Ninja",
    url: "/scooninja",
  },
  {
    id: 5,
    menu: "Scoo Preneur",
    url: "/scoo",
  },
  {
    id: 6,
    menu: "Scoo Club",
    url: "/scooClub",
  },
  {
    id: 7,
    menu: "Blogs",
    url: "https://scooevrentals.blog/",
  },
  {
    id: 8,
    menu: "Contacts us",
    url: "/Contact",
  },
];

export const privacyData = [
  {
    id: 1,
    title: "Privacy Policy",
    url: "/privacyPolicy",
  },
  {
    id: 2,
    title: "Terms & Conditions",
    url: "/TermCondition",
  },
  {
    id: 3,
    title: "Cancellation & Refund",
    url: "/CancelRefund",
  },
];

// Home Banner json
export const HomeBannerData = [
  {
    id: 1,
    head: "👍 Most reliable and eco-friendly E-scooter rentals platform in Bengaluru",
    title: "Our e-bikes are",
    details: "crafted to make  delivery a breeze.",
    para: "“Ensuring comfort and convenience for all types of commuters, while making errands greener",
    btn: "Rent now",
    btn2: " Enquire now",
    img: require("./../Assets/image/Banner/Hero.png"),
  },
  {
    id: 2,
    head: "👍 Most reliable and eco-friendly E-scooter rentals platform in Bengaluru",
    title: "Our e-bikes are",
    details: " crafted to make delivery a breeze.",
    para: "“Ensuring comfort and convenience for all types of commuters, while making errands greener",
    btn: "Rent now",
    btn2: " Enquire now",
    img: require("./../Assets/image/Banner/Hero.png"),
  },
  {
    id: 3,
    head: "👍 Most reliable and eco-friendly E-scooter rentals platform in Bengaluru",
    title: "Our e-bikes are",
    details: " crafted to make delivery a breeze.",
    para: "“Ensuring comfort and convenience for all types of commuters, while making errands greener",
    btn: "Rent now",
    btn2: " Enquire now",
    img: require("./../Assets/image/Banner/Hero.png"),
  },
];

export const b2bData = [
  {
    id: 1,
    img: require("./../Assets/image/B2B/webp/Zomato.webp"),
  },
  {
    id: 2,
    img: require("./../Assets/image/B2B/webp/swiggy.webp"),
  },
  {
    id: 3,
    img: require("./../Assets/image/B2B/webp/Dunzo.webp"),
  },
  {
    id: 4,
    img: require("./../Assets/image/B2B/webp/basket.webp"),
  },
  {
    id: 5,
    img: require("./../Assets/image/B2B/webp/shadow.webp"),
  },
  {
    id: 6,
    img: require("./../Assets/image/B2B/webp/zepto.webp"),
  },

  {
    id: 7,
    img: require("./../Assets/image/B2B/webp/1.webp"),
  },
  // {
  //   id: 8,
  //   img: require("./../Assets/image/B2B/2.png"),
  // },
  // {
  //   id: 9,
  //   img: require("./../Assets/image/B2B/3.png"),
  // },
];

export const ninjarandomImg = [
  {
    id: 0,
    img: require("../Assets/image/Assets/1.png"),
  },
  {
    id: 1,
    img: require("../Assets/image/Assets/2.png"),
  },
  {
    id: 2,
    img: require("../Assets/image/Assets/3.png"),
  },
  {
    id: 3,
    img: require("../Assets/image/Assets/4.png"),
  },
];

export const VehiclesSliderData = [
  {
    id: 1,
    img: require("./../Assets/image/Mession/group.webp"),
  },
  {
    id: 2,
    img: require("./../Assets/image/Mession/group2.webp"),
  },
  {
    id: 3,
    img: require("./../Assets/image/Mession/group3.webp"),
  },
  {
    id: 4,
    img: require("./../Assets/image/Mession/group4.webp"),
  },
];

export const RentSliderData = [
  {
    id: 1,
    img: require("./../Assets/image/RentBanner/1.webp"),
    btn: "Rent now",
    para: "Delve deep into the future of mobility with our ultra-affordable, business friendly, zero-emission rental E-scooters",
  },
  {
    id: 2,
    img: require("./../Assets/image/RentBanner/2.webp"),
    btn: "Rent now",
    para: "Five Es of Scoo- Efficiency, Eco-friendly, Economical, Electric, Ergonomic",
  },
  {
    id: 3,

    img: require("./../Assets/image/RentBanner/3.webp"),
    btn: "Rent now",
    para: "All-electric and Ecological! EV rental service for every commuting need! Rent a Scoo EV now!",
  },
];

export const StorySlider = [
  {
    id: 1,
    // img: require("./../../Assets/image/Story/story.png"),

    para: " We’re here to make your delivery process simpler and faster. With our  fleet of scooters, you can get the job done quickly and easily.",
  },
  {
    id: 2,
    // img: require("./../Assets/image/RentBanner/2.png"),

    para: " We’re here to make your delivery process simpler and faster. With our  fleet of scooters, you can get the job done quickly and easily.",
  },
  {
    id: 3,

    // img: require("./../Assets/image/RentBanner/3.png"),

    para: " We’re here to make your delivery process simpler and faster. With our  fleet of scooters, you can get the job done quickly and easily.",
  },
];

export const ScooWorkData = [
  {
    id: 1,
    icon: require("./../Assets/image/scoowork/scooter.png"),
    LineImg: require("./../Assets/image/scoowork/Line.png"),
    header: "Select Electric Scooter",
    para: " Choose the best suited electric scooter from our fleet.",
  },
  {
    id: 2,
    icon: require("./../Assets/image/scoowork/book.png"),
    LineImg: require("./../Assets/image/scoowork/Line.png"),
    header: "Choose A Plan",
    para: "Our packages start from as low as Rs.4500/- a month.",
  },
  {
    id: 3,
    icon: require("./../Assets/image/scoowork/user.png"),
    LineImg: require("./../Assets/image/scoowork/Line.png"),
    header: "Sign Up",
    para: "Fill out our rental form and have your chosen electric scooter delivered at your doorstep!",
  },
];

export const messionData = [
  {
    id: 1,

    header: "Our Vision",
    animation: "fade-right",
    para: "Build a community that enhances the future of e-mobility by catering to the need of every individual towards a Green &Frugal Future by providing the best available e-bikes in themarket",
  },

  {
    id: 2,
    animation: "fade-left",
    header: "Our Mission",
    para: "To capture the booming EV market with custom made rider rental options with a blend of technical expertise and commercial competent solution thereby developing a trusted customer network",
  },
];

export const ReviewsData = [
  {
    id: 0,
    heading:
      "“Working effortlessly for 10-12 hours in a day, Scoo Evs make my day run smooth and efficiently. With great mileage and zero-maintenance issues, I’ve been able to double my monthly income. I recommend everyone to switch to Scoo EVs!”",
    name: "Aman Chabra",
    brandLogo: require("./../Assets/image/B2B/webp/shadow.webp"),
    ReviewSlider: require("./../Assets/image/Reviews/webp/reviews.webp"),
    count: "",
  },
  {
    id: 1,
    heading:
      "“Scoo has been my preferred mode of commute for the last 4-months. Inconveniences I faced was quickly solved by the road-side assistance team. Scoo EV is the best bike rental partners in Bengaluru”",
    name: "Rohit Kapala",
    brandLogo: require("./../Assets/image/B2B/webp/Zomato.webp"),
    count: "",
    ReviewSlider: require("./../Assets/image/Reviews/webp/reviews2.webp"),
    starData: [
      {
        id: 0,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
      {
        id: 1,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
      {
        id: 2,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
      {
        id: 3,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
      {
        id: 4,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
    ],
  },
  {
    id: 2,
    heading:
      "“Everything was done seamlessly over the phone. From the initial inquiry till documentation and payment it was all an easy process to rent my E-scooter. On-boarding and service was quick and professional. Thank you Scoo EV team”",
    name: "Lohit Ramprasad",
    brandLogo: require("./../Assets/image/Reviews/unzo.png"),
    count: "",
    ReviewSlider: require("./../Assets/image/Reviews/webp/reviews3.webp"),
  },
  {
    id: 3,
    heading:
      "“Scoo EV inda naanu food delivery orders maadi monthly 40,000 earnings madthini.  Ella features Channgi ide, Ellaru Scoo EV ge switch madkoli”",
    name: "Kodi Suresh",
    brandLogo: require("./../Assets/image/B2B/webp/shadow.webp"),
    count: "",
    ReviewSlider: require("./../Assets/image/Reviews/webp/4.webp"),
  },
  {
    id: 4,
    heading:
      "“Scoo EV has been my preferred mode of commute for the last 4-months. Inconveniences I faced was quickly solved by the road-side assistance team. Scoo EV is the best bike rental partners in Bengaluru”",
    brandLogo: require("./../Assets/image/B2B/webp/Zomato.webp"),
    name: "Kausar Ahmed",
    count: "",
    ReviewSlider: require("./../Assets/image/Reviews/webp/5.webp"),
  },
  {
    id: 5,
    heading:
      "“Working effortlessly for 10-12 hours in a day, Scoo Evs make my day run smooth and efficiently. With great mileage and zero-maintenance issues, I’ve been able to double my monthly income. I recommend everyone to switch to Scoo EVs! ”",
    name: "Rathnakar Panda",
    brandLogo: require("./../Assets/image/B2B/webp/swiggy.webp"),
    count: "",
    ReviewSlider: require("./../Assets/image/Reviews/webp/6.webp"),
  },
  {
    id: 6,
    heading:
      "“Everything was done seamlessly over the phone. From the initial inquiry till documentation and payment it was all an easy process to rent my E-scooter. On-boarding and service was quick and professional. Thank you Scoo EV team!”",
    name: "Shailesh Kumar Bharati",
    brandLogo: require("./../Assets/image/B2B/webp/shadow.webp"),
    count: "",
    ReviewSlider: require("./../Assets/image/Reviews/webp/7.webp"),
  },
  {
    id: 7,
    heading:
      "“Scoo EV service, products and assistance has exceeded my expectations. Best vehicle for Bangalore traffic and fuel cost is a Scoo electric rental scooter.”",
    name: "Arun",
    brandLogo: require("./../Assets/image/B2B/webp/basket.webp"),
    count: "",
    ReviewSlider: require("./../Assets/image/Reviews/webp/8.webp"),
  },
  // {
  //   id: 8,
  //   heading:
  //     "“Everything was done seamlessly over the phone. From the initial inquiry till documentation and payment it was all an easy process to rent my E-scooter. On-boarding and service was quick and professional. Thank you Scoo EV team”",
  //   name: "Lohit Ramprasad",
  //   brandLogo: require("./../Assets/image/Reviews/unzo.png"),
  //   count: "",
  //   ReviewSlider: require("./../Assets/image/Reviews/reviews3.png"),
  // },
];

export const accordianData = [
  {
    id: 1,
    icon: require("./../Assets/image/scoowork/scooter.png"),
    question: "What types of electric scooters do you offer? ",
    answer:
      "We offer electric scooters that run with high efficiency ensuring low maintenance cost to the riders to commute across the city thereby reflecting a prominent savings in fuel costs  ",
  },
  {
    id: 2,
    question: "What is the process for renting your scooters? ",
    answer: "Renting a SCOO EV is literally a breeze!  ",
    title: "All you need to do are simple 3 steps to complete your KYC: ",
    kyc: [
      {
        id: 1,
        list: "1. Provide your driving silence ",
      },
      {
        id: 2,
        list: "2. Provide your Aadhaar linked mobile number ",
      },
      {
        id: 3,
        list: "3.  Provide your residential address ",
      },
    ],
    bottomTitle: "YESS! That’s it - You are ready to SCOO! ",
  },
  {
    id: 3,
    question: "Are your scooters safe and reliable?",
    answer:
      " Top leading brands like Swiggy, Zomato, Zepto, Big Basket, Dunzo; have chosen Scoo as their mobility partner We at Scoo promise that our e-bikes are the safest and most reliable ",
  },
  {
    id: 4,
    question: "How do I know which EV bike I should buy? ",
    answer:
      "If you are in a dilemma, you can just reach out to us at +91 77958 33817 We will suggest the best-suited e-bike for your need.  ",
  },
  {
    id: 5,
    question: "How is the service part taken care? ",
    answer:
      "We provide 100% assured service to every rider. If a minor issue, then the service center is equipped with skilled technicians to resolve your problem We have a swift Road Side Assistance (RSA) team who is just a call away for your major concerns  ",
  },
  {
    id: 6,
    question: "How does billing and payment work?",
    answer:
      "Scoo believes in a complete online payment method. You can pay through the registered razor pay account and our team verifies it. ",
  },
];

export const LuxaccordianData = [
  {
    id: 1,
    icon: require("./../Assets/image/scoowork/scooter.png"),
    question: "What makes Scoo Luxe unique? ",
    answer:
      " Scoo Luxe is a fleet of powerful luxury e-scooters that blend aesthetics, high performance,and futuristic technology, available for rent at the most affordable price and convenient payment options available in Bangalore.",
  },
  {
    id: 2,
    question: "How much does it cost to charge a Scoo Luxe?",
    answer:
      " The portable charge consumes only 4kWh of electricity for every full charge, which would cost Rs.20-Rs.30 for every full charge.",
  },
  {
    id: 3,
    question: " Can I charge the e-bike at home? ",
    answer:
      "  Yes, the Scoo Luxe can be charged anywhere using the portable charger provided with the  scooter.  ",
  },
  {
    id: 4,
    question: "Can I answer while riding the e-scooter?  ",
    answer:
      "The Scoo Luxe is a range of the most advanced technology available in the market. With Scoo Luxe, you can easily connect your smartphone to the e-scooter and easily answer calls, change music, and navigate using the touchscreen console.   ",
  },
  {
    id: 5,
    question: "Can I travel inter-state with the e-scooter?  ",
    answer:
      "Yes, Interstate travel is certainly possible with the Scoo Luxe e-scooter using portable chargers that can be connected to any port. ",
  },
  {
    id: 6,
    question: "Can I cancel my subscription mid-month? ",
    answer:
      " Yes, you can cancel or change your Scoo Luxe subscription at any time of the month. ",
  },
];

export const PreneuraccordianData = [
  {
    id: 1,
    icon: require("./../Assets/image/scoowork/scooter.png"),
    question: "Who is the Scoo-Preneur ?",
    answer:
      " Any individual who is already running a successful business or has an inclination to start his own business can opt to become a Scoo-Preneur Scoo-Preneur provides the platform to manage your own fleet of EV luxury bikes – Ola/Ather across Bangalore",
  },
  {
    id: 2,
    question: "How do I Enroll to become a Scoo-Preneur?",
    answer:
      " Become a Scoo-Preneur in three simple steps :Complete your KYC with Scoo Choose your subscription plan Choose your bike variant",
  },
  {
    id: 3,
    question: " What is the initial investment?",
    answer:
      "  Simple economic investment with a tailored made plan of Rs.5800/ per month. ",
  },
  {
    id: 4,
    question: "Benefits of Scoo-Preneur ? ",
    answer:
      "You can become a Scoo-Preneur by having just one bike to experience pure joy & advantage of Flexibility in managing your fleet (Rental Options for daily, hourly or weekend, & weekly Basis) You can charge your customer with the price – what you feel is right Low investment -High returns Minimum parking space is sufficient ",
  },
  {
    id: 5,
    question: "What about servicing of bikes? ",
    answer:
      "Scoo-Preneur bikes are completely backed with every kind of servicing or maintenance concerns. Note : if any severe damage occurred , that shall be borne by the end customer",
  },
  {
    id: 6,
    question: "How do I charge my Bike? ",
    answer: " Its as simple as charging your mobile at your home/store. ",
  },
  {
    id: 7,
    question: "As a Scoo Preneur how do I manage my fleet?",
    answer:
      "Every Scoo Preneur will be assigned a dedicated portal where you can Enroll , Track and monitor your fleet at any point of time.",
  },
  {
    id: 8,
    question: "How much can my monthly earnings? ",
    answer:
      " You, can approximately earn up to Rs.25,000/- per month (considering, you are a Scoo Preneur by starting off by renting out just 2 bikes a month)",
  },
];

export const clubaccordianData = [
  {
    id: 1,
    icon: require("./../Assets/image/scoowork/scooter.png"),
    question: "What is Scoo Club?",
    answer:
      " Scoo Club is an Elite EV Rental Membership prgram wherein you can book your luxury e-bikes and get it delivered to your home to ride across the city of Bangalore.",
  },
  {
    id: 2,
    question: "How can I join Scoo Club?",
    answer:
      " Scoo Club is a simple membership enrollment process, which can be completed over the phone with the assistance of our executives.",
  },
  {
    id: 3,
    question: " What are the benefits of joining Scoo Club?",
    answer:
      "By joining Scoo Club: You can additionally add 2 more users onto your Scoo Club Membership, with zero additional cost (They can enjoy all the facilities as you!) You along with your family/friends (any two more people) can book a e-scooter and get it delivered to your door step As a member you will also enjoy free pick up and drop service of e-bikes from your home Gain access to a community of electric scooter enthusiasts who share their experiences, knowledge, and tips ",
  },
  {
    id: 4,
    question: "What kind of vehicles do I get? ",
    answer:
      "At present we will be providing OLA S1, which gives a range of 80 – 100 Km. During the membership tenure, other e-bikes in the market (based on availability) shall be provided to the customer. ",
  },
  {
    id: 5,
    question: "What is the Scoo Club Membership fees?",
    answer:
      "Membership fees is completely pre-paid with an initial yearly deposit fee of Rs.9999/-, which is refundable after a year of membership. Incase early wrap up of the membership; deposit amount will not be refunded. Rental cost per day is Rs.499/- per e-bike booking. ",
  },
  {
    id: 6,
    question: "Do I need a license to drive a vehicle? ",
    answer: " Yes! Since it is a registered vehicle, need valid license. ",
  },
  {
    id: 7,
    question: " What other documents I need to ride a scooter?",
    answer: "Aadhar and Pan card for the KYC purpose.",
  },
  {
    id: 8,
    question: "What if the vehicle breakdown in the middle of the road?",
    answer:
      "Our RSA team will reach out to you, the will try to figure out the issue and replace the vehicle. Vehicle tracking is available.",
  },
  {
    id: 9,
    question:
      "What is the min and max time period that I can rent the vehicle?",
    answer: "24 Hours Prior, with a pick-up and drop facility of the e-bike.",
  },
  {
    id: 10,
    question: "Can I take multiple vehicles for a single subscription? ",
    answer:
      " You can book up to 3 bikes for a single subscription with no kilometer cap on the e-bike usage. ",
  },
];

export const LuxaccordianPreneurData = [
  {
    id: 1,
    icon: require("./../Assets/image/scoowork/scooter.png"),
    question: "Who is the Scoo-Preneur ?",
    answer:
      "Any individual who is already running a successful business or has an inclination to start his own business can opt to become a Scoo-Preneur ",
    list: "Scoo-Preneur provides the platform to manage your own fleet of EV luxury bikes – Ola/Ather across bangalore",
  },
  {
    id: 2,
    question: " How do I Enroll to become a Scoo-Preneur ?",
    answer: "Become a Scoo-Preneur in three simple steps :",
    list: "1: Complete your KYC with Scoo",
    list2: "2: Choose your subscription plan",
    list3: "3: Choose your bike",
  },
  {
    id: 3,
    question: "What is the initial investment?",
    answer:
      "Simple investment with a tailored made Plan of RS 5800/- Per Month",
  },
  {
    id: 4,
    question: "Benefits of Scoo-Preneur ?",
    list: "1: You can become a Scoo-Preneur by having just one bike to experience purejoy & advantage of it",
    list2:
      "2: Flexibility in Managing your Fleet (Rental Options for daily, Hourly or Weekend, Weekly Basis)",
    list3:
      "3: You can charge your customer with the price – what you feel is right",
    list4: "4: Low investment -High returns",
    list5: "5: No additional cost for any marketing",
    list6: "6 :Bare minimum of Parking Space is Sufficient",
  },
  {
    id: 5,
    question: "What about Servicing? ",
    answer:
      "Scoo-Preneur has an advantage over the others has the bikes are completely secured with any kind of servicing or maintenance issue by Scoo itself, However",
    list: "Note : if any severe damage occurred , that shall be borne by the End customer",
  },
  {
    id: 6,
    question: "How do I charge my Bike?",
    answer: "Its as simple as charging your mobile at your store",
  },
  {
    id: 7,
    question: "As a Scoo-Preneur how do I manage my fleet?",
    answer:
      "Every Scoo-Preneur will be assigned a dedicated Portal where you can Enroll,Track and monitor your fleet at any point of time",
  },
  {
    id: 8,
    question: "Preferable Rate card?",
    answer: " Preferable Rate card?",
    list: "Per day charges – RS 500/-",
    list2:
      "Considering 20 Bright days in a month – You can earn up to RS 10,000/- Per month ",
    list3:
      " Additional weekend charges can be applicable Assured Returns 100% Returns",
    list4: "with minimal investment",
  },
];

export const RentProductCardData = [
  {
    id: 1,

    head: "Select Plan",

    ruppee: "6,000",

    title: "Quantum",

    month: "per month",

    btn: "Rent now",

    performance: [
      // {
      //   id: 1,

      //   text: "1 Month rent - 6800/- per month",
      // },

      {
        id: 2,
        text: "3 months - 6000/- per month",
        url: "https://scoomerchandise.myshopify.com/products/quantum-bziness-quarterly-6000-00-per-month",
      },

      {
        id: 3,

        text: "6 months - 5900/- per month",
        url: "https://scoomerchandise.myshopify.com/products/quantum-bziness-half-yearly-5900-00-per-month",
      },

      {
        id: 4,

        text: "1 year - 5700/- per month",
        url: "https://scoomerchandise.myshopify.com/products/quantum-bziness-yearly-5700-00-per-month",
      },
    ],

    RentQuantumSliderData: [
      {
        id: 1,

        img: require("./../Assets/image/Rentals/Quantum/webp/1.webp"),
      },

      {
        id: 2,

        img: require("./../Assets/image/Rentals/Quantum/webp/2.webp"),
      },

      {
        id: 3,

        img: require("./../Assets/image/Rentals/Quantum/webp/3.webp"),
      },

      {
        id: 4,

        img: require("./../Assets/image/Rentals/Quantum/webp/4.webp"),
      },

      {
        id: 5,

        img: require("./../Assets/image/Rentals/Quantum/webp/5.webp"),
      },
    ],
  },

  {
    id: 2,

    head: "Select Plan",

    ruppee: "5,500",

    title: "Scoo NYX",

    month: "per month",

    btn: "Rent now",

    performance: [
      // {
      //   id: 1,

      //   text: "1 Month rent - 6800/- per month",
      // },

      {
        id: 2,

        text: "3 months - 5900/- per month",
        url: "https://scoomerchandise.myshopify.com/products/hero-nyx-quaterly",
      },

      {
        id: 3,

        text: "6 months - 5700/- per month",
        url: "https://scoomerchandise.myshopify.com/products/hero-nyx-half-yearly",
      },

      {
        id: 4,

        text: "1 year - 5500/- per month",
        url: "https://scoomerchandise.myshopify.com/products/hero-nyx",
      },
    ],

    RentProductSliderData: [
      {
        id: 1,

        img: require("./../Assets/image/Rentals/NYX/webp/1.webp"),
      },

      {
        id: 2,

        img: require("./../Assets/image/Rentals/NYX/webp/2.webp"),
      },

      {
        id: 3,

        img: require("./../Assets/image/Rentals/NYX/webp/3.webp"),
      },

      {
        id: 4,

        img: require("./../Assets/image/Rentals/NYX/webp/4.webp"),
      },
    ],
  },
];

// SCOO LUX JSON
export const LuxBannerSliderData = [
  {
    id: 1,
    img: require("./../Assets/image/RentBanner/CTA-Banner.webp"),
    btn: "Rent now",
    para: "Live the dream ride the future SCOO Luxe Top-notch performance, ultimate safety, pure luxury Dare to experience! Rent now !",
  },
  // {
  //   id: 2,
  //   img: require("./../Assets/image/RentBanner/2.png"),
  //   btn: "Rent now",
  //   para: " We’re here to make your delivery process simpler and faster. With our  fleet of scooters, you can get the job done quickly and easily.",
  // },
  // {
  //   id: 3,

  //   img: require("./../Assets/image/RentBanner/3.png"),
  //   btn: "Rent now",
  //   para: " We’re here to make your delivery process simpler and faster. With our  fleet of scooters, you can get the job done quickly and easily.",
  // },
];

// export const LuxProductSliderData = [
//   {
//     id: 1,
//     img: require("./../Assets/image/Assets/scooty/1.png"),
//   },
//   {
//     id: 2,
//     img: require("./../Assets/image/Assets/scooty/2.png"),
//   },
//   {
//     id: 3,
//     img: require("./../Assets/image/Assets/scooty/3.png"),
//   },
// ];

export const LuxProductSliderData2 = [
  {
    id: 1,
    img: require("./../Assets/image/Assets/scooty/1.png"),
  },
  {
    id: 2,
    img: require("./../Assets/image/Assets/scooty/2.png"),
  },
  {
    id: 3,
    img: require("./../Assets/image/Assets/scooty/3.png"),
  },
];

export const LuxSooterSliderData = [
  {
    id: 1,
    img: require("./../Assets/image/Bounce2.png"),
  },
  // {
  //   id: 2,
  //   img: require("./../Assets/image/Assets/blueScooty.png"),
  // },
];

export const LuxReviewsData = [
  {
    id: 0,
    heading:
      "Incredible ride quality, powerful performance, and luxury coupled with futuristic features. Arguably, the most affordable luxury Electric Scooter rental service in Bangalore. Overall, very elated with the service and quality of Scoo Luxe.",
    name: "Vivek",
    brandLogo: require("./../Assets/image/B2B/webp/shadow.webp"),
    ReviewSlider: require("./../Assets/image/Reviews2/reviews.webp"),
    count: "",
  },
  {
    id: 1,
    heading:
      "I found Scoo Luxe on Instagram, it was exactly what I needed for my commuting needs. I rented the Scoo Luxe, and the service team helped me pick the most relevant subscription for my lifestyle. My commuting worries are now long gone, with Scoo’s luxury EVs tending to my travel needs in a cost-effective manner. I’m very happy with my experience with the Scoo Luxe. Thank you Scoo Team!",
    name: "Esther Howard",
    brandLogo: require("./../Assets/image/B2B/webp/Zomato.webp"),
    count: "",
    ReviewSlider: require("./../Assets/image/Reviews2/reviews2.webp"),
    starData: [
      {
        id: 0,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
      {
        id: 1,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
      {
        id: 2,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
      {
        id: 3,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
      {
        id: 4,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
    ],
  },
  // {
  //   id: 2,
  //   heading:
  //     "Hailing from the hustling and bustling Silicon Valley of India, E-bikes have often been my stress-busters. During the weekends, I take a break from my routine and step outside to the scenic locations nearby. Quality performance on the roads and excellent range to travel the outskirts of Bangalore to unwind and relax with the safety of the roadside assistance team backing me up in case of an inconvenience. Scoo EV is the best e-bike rental service in Bangalore!",
  //   brandLogo: require("./../Assets/image/Reviews/unzo.png"),
  //   name: "Leslie Alexander",
  //   count: "",
  //   ReviewSlider: require("./../Assets/image/Reviews/reviews3.png"),
  // },
  // {
  //   id: 3,
  //   heading:
  //     "My commute to my workplace is a long and extensive journey in the metro. My commute to and fro the metro station and the ride back home is made easy with the comfortable and cost-effective Scoo Luxe.",
  //   name: "Courtney Henry",
  //   brandLogo: require("./../Assets/image/B2B/shadow.png"),
  //   count: "",
  //   ReviewSlider: require("./../Assets/image/Reviews/4.png"),
  // },
];

export const LuxProductCardData = [
  {
    id: 1,
    head: "Select Plan",
    ruppee: "6800",
    title: "Ola S1",
    month: "per month",
    btn: "Rent now",
    heading: "Ols",
    name:"Unleash the electric storm",
    performance: [
      {
        id: 1,
        text: "1 Month rent - 6800/- per month",
      },
      {
        id: 2,
        text: "3 months - 6500/- per month",
      },
      {
        id: 3,
        text: "6 months - 6000/- per month",
      },
      {
        id: 4,
        text: "1 year - 5800/- per month",
      },
    ],
    LuxProductOlaSliderData: [
      {
        id: 1,
        img: require("./../Assets/image/Assets/scooty/webp/1.webp"),
      },
      {
        id: 2,
        img: require("./../Assets/image/Assets/scooty/webp/2.webp"),
      },
      {
        id: 3,
        img: require("./../Assets/image/Assets/scooty/webp/3.webp"),
      },
    ],
  },
  {
    id: 2,
    head: "Select Plan",
    ruppee: "6200",
    title: "Bounce Infinity",
    name:"Ride unlimited, unbound.",
    month: "per month",
    btn: "Rent now",

    performance: [
      {
        id: 1,
        text: "1 Month rent - 6200/- per month",
      },
      {
        id: 2,
        text: "3 months - 6100/- per month",
      },
      {
        id: 3,
        text: "6 months - 6000/- per month",
      },
      {
        id: 4,
        text: "1 year - 5800/- per month",
      },
    ],

    LuxProductSliderData: [
      {
        id: 1,
        img: require("./../Assets/image/Bounce2.png"),
      },
      {
        id: 2,
        img: require("./../Assets/image/Bounce.png"),
      },
      // {
      //   id: 3,
      //   img: require("./../Assets/image/Assets/scooty/webp/white3.webp"),
      // },
      // {
      //   id: 3,
      //   img: require("./../Assets/image/Assets/scooty/webp/white4.webp"),
      // },
    ],
  },
  // {
  //   id: 3,

  //   head: "Ola S1 Pro",
  //   ruppee: "5,000",
  //   title: "Ola S1 Pro",
  //   month: "per month",
  //   btn: "Rent now",

  //   performance: [
  //     {
  //       id: 1,
  //       text: "Yearly-4500/month-3",
  //     },
  //     {
  //       id: 2,
  //       text: "Half-4600/month-3",
  //     },
  //     {
  //       id: 3,
  //       text: "Quaterly-4800/month-3",
  //     },
  //   ],
  // },
];

// SCOO NINJA JSON

export const ninjaSliderData = [
  {
    id: 1,

    img: require("./../Assets/image/Assets/Shape2.svg"),
    head: "Assured monthly earnings with Scoo EV Rentals",
    amount: "25,000/- to 35,000/-",
    btn: "Contact us now!",
  },
  // {
  //   id: 2,

  //   img: require("./../Assets/image/Assets/Shape2.svg"),
  //   head: "Assured monthly earnings with Scoo EV Rentals",
  //   amount: "25,000/- to 35,000/-",
  //   btn: "Contact us now!",
  // },
  // {
  //   id: 3,
  //   img: require("./../Assets/image/Assets/Shape2.svg"),
  //   head: "Assured monthly earnings with Scoo EV Rentals",
  //   amount: "25,000/- to 35,000/-",
  //   btn: "Contact us now!",
  // },
];

export const ninjaKycData = [
  {
    id: 1,
    img: require("./../Assets/image/Assets/adhaar_logo.png"),
    head: "Aadhar Card",
  },
  {
    id: 2,
    img: require("./../Assets/image/Assets/pancard_logo.png"),
    head: "PAN Card",
  },
  {
    id: 3,
    img: require("./../Assets/image/Assets/driving_licence_logo.png"),
    head: "Driving License or LLR",
  },
  {
    id: 4,
    img: require("./../Assets/image/Assets/bankaccount_logo.png"),
    head: "Bank Account details",
  },
];

export const ninjaphoneData = [
  {
    id: 1,
    img: require("./../Assets/image/Assets/phone.png"),
    number: " +91 77958 34737",
  },
  {
    id: 2,
    img: require("./../Assets/image/Assets/phone.png"),
    number: "+91 63602 98579",
  },
  {
    id: 3,
    img: require("./../Assets/image/Assets/phone.png"),
    number: "+91 63603 00926",
  },
];

export const NinjaReviewsData = [
  {
    id: 0,
    heading:
      "“Working effortlessly for 10-12 hours in a day, Scoo Evs make my day run smooth and efficiently. With great mileage and zero-maintenance issues, I’ve been able to double my monthly income. I recommend everyone to switch to Scoo EVs!”",
    name: "Aman Chabra",
    brandLogo: require("./../Assets/image/B2B/shadow.png"),
    ReviewSlider: require("./../Assets/image/Reviews/reviews.png"),
    count: "",
  },
  {
    id: 1,
    heading:
      "“Scoo has been my preferred mode of commute for the last 4-months. Inconveniences I faced was quickly solved by the road-side assistance team. Scoo EV is the best bike rental partners in Bengaluru”",
    name: "Rohit Kapala",
    brandLogo: require("./../Assets/image/Reviews/zomato.png"),
    count: "",
    ReviewSlider: require("./../Assets/image/Reviews/reviews2.png"),
    starData: [
      {
        id: 0,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
      {
        id: 1,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
      {
        id: 2,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
      {
        id: 3,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
      {
        id: 4,
        star: require("./../Assets/image/Reviews/webp/Star.webp"),
      },
    ],
  },
  {
    id: 2,
    heading:
      "“Everything was done seamlessly over the phone. From the initial inquiry till documentation and payment it was all an easy process to rent my E-scooter. On-boarding and service was quick and professional. Thank you Scoo EV team”",
    name: "Lohit Ramprasad",
    brandLogo: require("./../Assets/image/Reviews/unzo.png"),
    count: "",
    ReviewSlider: require("./../Assets/image/Reviews/reviews3.png"),
  },
  {
    id: 3,
    heading:
      "“Scoo EV inda naanu food delivery orders maadi monthly 40,000 earnings madthini.  Ella features Channgi ide, Ellaru Scoo EV ge switch madkoli”",
    name: "Kodi Suresh",
    brandLogo: require("./../Assets/image/B2B/shadow.png"),
    count: "",
    ReviewSlider: require("./../Assets/image/Reviews/4.png"),
  },
];

export const blognData = [
  {
    id: 1,
    icon: require("./../Assets/image/Blog/blog.png"),
    btn: " Offers & Coupons",
    blogtitle: "Lorem ipsum dolor sit amet consectetur. Mauris leodolor dolor.",
    day: "2 days ago",
    views: "110k views",
    facebook: require("./../Assets/image/Blog/Facebook.png"),
    instagram: require("./../Assets/image/Blog/Instagram.png"),
    Linkedin: require("./../Assets/image/Blog/Linkedin.png"),
    Twitter: require("./../Assets/image/Blog/Twitter.png"),
  },
  {
    id: 2,
    icon: require("./../Assets/image/Blog/blog.png"),
    btn: "Daily read",
    blogtitle: "Lorem ipsum dolor sit amet consectetur. Mauris leodolor dolor.",
    day: "2 days ago",
    views: "110k views",
    facebook: require("./../Assets/image/Blog/Facebook.png"),
    instagram: require("./../Assets/image/Blog/Instagram.png"),
    Linkedin: require("./../Assets/image/Blog/Linkedin.png"),
    Twitter: require("./../Assets/image/Blog/Twitter.png"),
  },
  {
    id: 3,
    icon: require("./../Assets/image/Blog/blog.png"),
    btn: "Blog",
    blogtitle: "Lorem ipsum dolor sit amet consectetur. Mauris leodolor dolor.",
    day: "2 days ago",
    views: "110k views",
    facebook: require("./../Assets/image/Blog/Facebook.png"),
    instagram: require("./../Assets/image/Blog/Instagram.png"),
    Linkedin: require("./../Assets/image/Blog/Linkedin.png"),
    Twitter: require("./../Assets/image/Blog/Twitter.png"),
  },
];

export const RentalsData = [
  {
    id: 1,
    img: require("./../Assets/image/Rentals/scooty1.png"),
  },

  {
    id: 2,
    img: require("./../Assets/image/Rentals/scooty2.png"),
  },
  {
    id: 3,
    img: require("./../Assets/image/Rentals/scooty3.png"),
  },
];

export const Rentals2Data = [
  {
    id: 1,
    img: require("./../Assets/image/Rentals/scoo1.png"),
  },
  {
    id: 2,
    img: require("./../Assets/image/Rentals/scoo2.png"),
  },
  {
    id: 3,
    img: require("./../Assets/image/Rentals/scoo3.png"),
  },
];

export const Rentalsection = [
  {
    id: 1,
    img: require("./../Assets/image/Rentals/watch.png"),
    NyxTilte: "Scoo NYX",
    title: "Speed",
    kmeters: "50-55 KM/h",
  },
  {
    id: 2,
    img: require("./../Assets/image/Rentals/watch.png"),
    title: "Speed",
    kmeters: "50-55 KM/h",
  },
  {
    id: 3,
    img: require("./../Assets/image/Rentals/watch.png"),
    title: "Speed",
    kmeters: "50-55 KM/h",
  },
];

export const RangeData = [
  {
    id: 1,
    img: require("./../Assets/image/Rentals/watch.png"),
    title: "Speed",
    kmeters: "45 kmph",
  },
  {
    id: 2,
    img: require("./../Assets/image/Rentals/watch.png"),
    title: "Range",
    kmeters: "120 kms",
  },
  {
    id: 3,
    img: require("./../Assets/image/Rentals/watch.png"),
    title: "Capacity",
    kmeters: "150-200kg",
  },
];

export const RangeData2 = [
  {
    id: 1,
    img: require("./../Assets/image/Rentals/watch.png"),
    title: "Speed",
    kmeters: "40-42 KM/h",
  },
  {
    id: 2,
    img: require("./../Assets/image/Rentals/watch.png"),
    title: "Range",
    kmeters: "110 Kms",
  },
  {
    id: 3,
    img: require("./../Assets/image/Rentals/watch.png"),
    title: "Capacity",
    kmeters: "150-200kg",
  },
];

export const accessibilityListData = [
  {
    id: 0,
    count: "1",
    head: "Accessibility",
    para: "Scoo EV is the first e-bike rental firm to accelerate the EV two wheeler rental domain into a complete rider-centric module",
    img: require("./../Assets/image/Assecbility/webp/1.webp"),
  },
  {
    id: 1,
    count: "2",
    head: "Adaptability",
    para: "Scoo EV is the first e-bike rental firm to accelerate the EV two wheeler rental domain into a complete rider-centric module",
    img: require("./../Assets/image/Assecbility/webp/2.webp"),
  },
  {
    id: 2,
    count: "3",
    head: "Affordability",
    para: "Scoo EV is the first e-bike rental firm to accelerate the EV two wheeler rental domain into a complete rider-centric module",
    img: require("./../Assets/image/Assecbility/webp/3.webp"),
  },
];

export const acessibilitySlider = [
  {
    id: 1,
    img: require("./../Assets/image/Assecbility/slider.png"),
  },
  {
    id: 2,
    img: require("./../Assets/image/Assecbility/slider.png"),
  },
  {
    id: 3,
    img: require("./../Assets/image/Assecbility/slider.png"),
  },
];

export const petrolcardData = [
  {
    id: 1,
    img: require("./../Assets/image/Engines/petrol.png"),
    para: "Monthly earnings of a Last-mile delivery partner ",
    amt: "- Rs.35,000/-",
  },
  {
    id: 2,
    img: require("./../Assets/image/Engines/petrol.png"),
    para: "General vehicle maintenance charges",
    amt: "- Rs.5000/-",
  },
  {
    id: 3,
    img: require("./../Assets/image/Engines/petrol.png"),
    para: "Additional over head charges ",
    amt: "- Rs.500/-",
  },
  {
    id: 4,
    img: require("./../Assets/image/Engines/petrol.png"),
    para: "Cost to refuel the Petrol vehicle   ",
    amt: "- Rs.10,000/-",
    text: "(45Kmpl mileage)",
  },
];
export const petrolcardData2 = [
  {
    id: 1,
    img: require("./../Assets/image/Engines/Vector.png"),
    para: "Monthly earnings of a Last-mile delivery partner",
    amt: "- Rs.35,000/-",
  },
  {
    id: 2,
    img: require("./../Assets/image/Engines/Vector.png"),
    para: "General vehicle maintenance charges ",
    amt: " 0",
  },
  {
    id: 3,
    img: require("./../Assets/image/Engines/Vector.png"),
    para: "Cost to recharge the Battery ",
    amt: "- Rs.500/-",
    text: "per month",
  },
  {
    id: 4,
    img: require("./../Assets/image/Engines/Vector.png"),
    para: "Scoo EV Rental fee – starting at ",
    amt: " Rs.4500/-",
    text: "per month",
  },
];

// LEST SCOO JSON
export const blogSectionData = [
  {
    id: 1,
    icon: require("./../Assets/image/Blog/Rectangle 21.png"),
    btn: " Offers & Coupons",
    blogtitle: "Lorem ipsum dolor sit amet consectetur. Mauris leodolor dolor.",
    day: "2 days ago",
    views: "110k views",
    facebook: require("./../Assets/image/Blog/Facebook.png"),
    instagram: require("./../Assets/image/Blog/Instagram.png"),
    Linkedin: require("./../Assets/image/Blog/Linkedin.png"),
    Twitter: require("./../Assets/image/Blog/Twitter.png"),
  },
  {
    id: 2,
    icon: require("./../Assets/image/Blog/Rectangle 21.png"),
    btn: " Offers & Coupons",
    blogtitle: "Lorem ipsum dolor sit amet consectetur. Mauris leodolor dolor.",
    day: "2 days ago",
    views: "110k views",
    facebook: require("./../Assets/image/Blog/Facebook.png"),
    instagram: require("./../Assets/image/Blog/Instagram.png"),
    Linkedin: require("./../Assets/image/Blog/Linkedin.png"),
    Twitter: require("./../Assets/image/Blog/Twitter.png"),
  },
  {
    id: 3,
    icon: require("./../Assets/image/Blog/Rectangle 21.png"),
    btn: " Offers & Coupons",
    blogtitle: "Lorem ipsum dolor sit amet consectetur. Mauris leodolor dolor.",
    day: "2 days ago",
    views: "110k views",
    facebook: require("./../Assets/image/Blog/Facebook.png"),
    instagram: require("./../Assets/image/Blog/Instagram.png"),
    Linkedin: require("./../Assets/image/Blog/Linkedin.png"),
    Twitter: require("./../Assets/image/Blog/Twitter.png"),
  },
  {
    id: 4,
    icon: require("./../Assets/image/Blog/Rectangle 21.png"),
    btn: " Offers & Coupons",
    blogtitle: "Lorem ipsum dolor sit amet consectetur. Mauris leodolor dolor.",
    day: "2 days ago",
    views: "110k views",
    facebook: require("./../Assets/image/Blog/Facebook.png"),
    instagram: require("./../Assets/image/Blog/Instagram.png"),
    Linkedin: require("./../Assets/image/Blog/Linkedin.png"),
    Twitter: require("./../Assets/image/Blog/Twitter.png"),
  },
];
// CONTACT JSON

export const ContactAddressData = [
  // {
  //   id: 1,
  //   img: require("./../Assets/image/Contact/Icon.png"),
  //   head: "Head office",
  //   time: "Mon-Fri 9am to 6pm.",
  //   para: "2nd Floor, Supreme Overseas Exports Building Krishna Rajendra Road 7th Block, Jayanagar Bengaluru, Karnataka 560070 India.",
  //   text: "Contact Number",
  //   number: "+91 77958 33836, +91 77958 33840",
  // },
  {
    id: 2,
    img: require("./../Assets/image/Contact/Icon.png"),
    head: "Our Fleet",
    time: "Mon-Sat 24/7",
    para: "NO.786/289, Banashankari Nilaya, Rajiv Gandhi Rd, JP Nagar 6th Phase, Jarganahalli, Bengaluru, Karnataka 560078",
    text: "Contact Number",
    number: "080711 75901  ",
     
  },
];

export const ContactImageData = [
  {
    id: 1,
    img: require("./../Assets/image/Contact/1.png"),
    name: "Amit S Clospet",
    title: "CEO",
    para: "Lorem ipsum dolor sit amet consectetur. Pharetra libero eget.",
    icon: require("./../Assets/image/Contact/tiwtter.png"),
    icon2: require("./../Assets/image/Contact/linkdin.png"),
  },
  {
    id: 2,
    img: require("./../Assets/image/Contact/2.png"),
    name: "Aparna",
    title: "CEO",
    para: "Lorem ipsum dolor sit amet consectetur. Pharetra libero eget.",
    icon: require("./../Assets/image/Contact/tiwtter.png"),
    icon2: require("./../Assets/image/Contact/linkdin.png"),
  },
  {
    id: 3,
    img: require("./../Assets/image/Contact/3.png"),
    name: "Suresh CR",
    title: "TECHINICAL ADVISOR",
    para: "Lorem ipsum dolor sit amet consectetur. Pharetra libero eget.",
    icon: require("./../Assets/image/Contact/tiwtter.png"),
    icon2: require("./../Assets/image/Contact/linkdin.png"),
  },
  {
    id: 4,
    img: require("./../Assets/image/Contact/4.png"),
    name: "Shiva Shankar S",
    title: "VICE PRESIDENT",
    para: "Lorem ipsum dolor sit amet consectetur. Pharetra libero eget.",
    icon: require("./../Assets/image/Contact/tiwtter.png"),
    icon2: require("./../Assets/image/Contact/linkdin.png"),
  },
];
